.photography {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap between columns */
}

.column {
  flex: 1; /* Each column takes equal width */
}

.column img {
  width: 100%; /* Make images take full width of the column */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space between images */
}
