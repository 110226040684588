.aboutMeContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5; /* Example background color */
  }
  
  .aboutMeContent {
    max-width: 600px; /* Adjust max-width as needed */
    margin-top: 20px; /* Adjust margin-top as needed for spacing from the top */
    text-align: center;
    font-size: 18px; /* Adjust font size as needed */
    line-height: 1.6; /* Adjust line height for better readability */
  }
  
  .aboutMeContent p {
    margin-bottom: 20px; /* Spacing between paragraphs */
  }
  